@import url('https://fonts.googleapis.com/css2?family=Honk&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gluten';
  src: url('https://fonts.googleapis.com/css2?family=Gluten:wght@100..900&display=swap');
  font-display: swap;
}

body {
  font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  20% { opacity: 0.1; }
  40%, 60% { opacity: 0.15; }
  80% { opacity: 0.1; }
}

.background-question {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.gluten-font {
  font-family: 'Gluten', cursive;
  font-variation-settings: "wght" 400;  /* Adjust weight as needed */
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-pop-in {
  animation: pop-in 0.5s cubic-bezier(0.26, 0.53, 0.74, 1.48) forwards;
}

.fade-enter, .fade-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.app-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  background-color: transparent;
}

.page-wrapper.transitioning {
  opacity: 0;
  transform: translateY(20px);
}